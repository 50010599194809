import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import Layout from '../ui/layout/Layout';
import {Section, Container, Row, Column} from '../ui/layout/Grid';

import Headline from '../ui/components/Headline';
import Paragraph from '../ui/components/Paragraph';
import lang from '../lang/pages/apothekentour.lang';
import Header from '../ui/layout/Header';
import HeroImage from '../assets/images/apotheken-tour/img-header-gewinnspiel.png';
import ParticipateImage from '../assets/images/apotheken-tour/img-teilgenommen.png';
import Image from '../ui/components/Image';
import FileUpload from '../ui/domains/sales-agent-user-interactions/UserCampaignSubmissions/FileUpload';
import CampaignInstructions from '../ui/domains/sales-agent-user-interactions/UserCampaignSubmissions/CampaignInstructions';
import Lightbox from '../ui/components/Lightbox';
import {ReactComponent as StarsSvg} from '../assets/images/trainings/img-overlay--punkte.svg';
import NoCampaign from '../assets/images/aktionen/img-keine-aktion.png';
import {
    selectPendingRequestsSuccess,
    selectPendingRequestsError,
} from '../model/backend-api';
import {LOAD_CURRENT_COMPETITION} from '../model/user-pharmacy-tour-submission/actions';

import {
    updloadSubmissionImageFile,
    selectIsFormSendSuccess,
    selectCurrentCompetition,
    loadCurrentCompetition,
} from '../model/user-pharmacy-tour-submission';

const Apothekentour = () => {
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [isInitialSuccess, setIsInitialSuccess] = useState(false);
    const [isInitialError, setIsInitialError] = useState(false);

    const pendingRequestsSuccess = useSelector(selectPendingRequestsSuccess);
    const pendingRequestsError = useSelector(selectPendingRequestsError);
    const isUploadSuccess = useSelector(selectIsFormSendSuccess);
    const currentCompetition = useSelector(selectCurrentCompetition);

    const isCurrentCompetitionSuccess =
        pendingRequestsSuccess.type === LOAD_CURRENT_COMPETITION;
    const isCurrentCompetitionError =
        pendingRequestsError.type === LOAD_CURRENT_COMPETITION;

    const dispatch = useDispatch();
    useEffect(() => {
        if (isCurrentCompetitionSuccess) {
            setIsInitialSuccess(true);
        }
        if (isCurrentCompetitionError) {
            setIsInitialError(true);
        }
    }, [isCurrentCompetitionSuccess, isCurrentCompetitionError]);

    useEffect(() => {
        dispatch(loadCurrentCompetition());
    }, [dispatch]);

    useEffect(() => {
        if (isUploadSuccess) {
            setLightboxOpen(isUploadSuccess);
        }
    }, [isUploadSuccess]);

    const participated =
        currentCompetition &&
        currentCompetition?.submissionStatus &&
        currentCompetition?.submissionStatus !== 'rejected';

    return (
        <Layout>
            <Header img={HeroImage} alt={lang.header.alt} paddingTop>
                <Headline type="h1">{lang.header.headline}</Headline>
                <Paragraph>{lang.header.paragraph}</Paragraph>
            </Header>
            {!isInitialSuccess && !isInitialError ? null : (
                <>
                    {isInitialError ? (
                        <Section padding="2rem 0 0 0" noLineHeight>
                            <Container
                                width="narrow"
                                className="text-center"
                                noGutter>
                                <Headline type="h2">
                                    {lang.noTourSection.headline}
                                </Headline>
                                <Paragraph>
                                    {lang.noTourSection.paragraph}
                                </Paragraph>
                                <Image
                                    src={NoCampaign}
                                    alt={lang.noTourSection.alt}
                                />
                            </Container>
                        </Section>
                    ) : participated ? (
                        <Section padding="2rem 0 0 0" noLineHeight>
                            <Container
                                width="narrow"
                                className="text-center"
                                noGutter>
                                <Headline type="h2">
                                    {lang.participatedSection.headline}
                                </Headline>
                                <Paragraph>
                                    {lang.participatedSection.paragraph}
                                </Paragraph>
                                <Image
                                    src={ParticipateImage}
                                    alt={lang.participatedSection.alt}
                                />
                            </Container>
                        </Section>
                    ) : (
                        <>
                            <Section>
                                <Container width="wide">
                                    <Row>
                                        <Column xl={6}>
                                            <Headline type="h2">
                                                {
                                                    lang.tourSection.description
                                                        .headline
                                                }
                                            </Headline>
                                            <Paragraph>
                                                {
                                                    lang.tourSection.description
                                                        .paragraph
                                                }
                                            </Paragraph>
                                        </Column>
                                        <Column xl={6}>
                                            {lang.tourSection.steps.map(
                                                (step, index) => {
                                                    return (
                                                        <CampaignInstructions
                                                            key={index}
                                                            image={step.image}
                                                            headline={
                                                                step.headline
                                                            }>
                                                            {step.paragraph}
                                                        </CampaignInstructions>
                                                    );
                                                }
                                            )}
                                        </Column>
                                    </Row>
                                </Container>
                            </Section>
                            <ConnectedFileUpload
                                theme="grey"
                                dropzoneText={lang.dragAndDropSection.paragraph}
                            />
                        </>
                    )}
                </>
            )}

            <Lightbox
                isOpen={lightboxOpen}
                onClose={() => setLightboxOpen(false)}>
                <Section>
                    <Container width="narrow" className="text-center">
                        <StarsSvg />
                        <Headline fontWeight="bold" type="h5">
                            {lang.apothekentourSubmission.success.headline}
                        </Headline>
                        <Paragraph size="small">
                            {lang.apothekentourSubmission.success.paragraph}
                        </Paragraph>
                    </Container>
                </Section>
            </Lightbox>
        </Layout>
    );
};

const ConnectedFileUpload = (props) => {
    const dispatch = useDispatch();
    const handleSubmit = (imageFile) => {
        dispatch(updloadSubmissionImageFile(imageFile));
    };

    return <FileUpload {...props} onSubmit={handleSubmit} />;
};

export default Apothekentour;
