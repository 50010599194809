import React from 'react';

import Link from '../../ui/components/Link';
import {routes} from '../../model/navigation';
import {ReactComponent as Stars} from '../../assets/images/punktehistorie/img-punktehistorie.svg';
import {ReactComponent as Photo} from '../../assets/images/aktionen/img-foto.svg';
import {ReactComponent as Work} from '../../assets/images/aktionen/img-arbeit.svg';

export default {
    header: {
        headline: (
            <i>
                <strong>Das Gewinnspiel</strong> zur Apothekentour
            </i>
        ),
        paragraph: (
            <>
                Du hast die Apothekentour besucht und dort ein Foto an unserem
                GeloMyrtol® forte Stand gemacht? Dann kannst Du jetzt auch an
                unserem Gewinnspiel teilnehmen.
            </>
        ),
        alt: 'Illustration einer Apotherkerin mit Fotoapparat',
    },
    participatedSection: {
        headline: (
            <>
                <strong>Du hast bereits</strong>{' '}
                <i>am Gewinnspiel teilgenommen</i>
            </>
        ),
        paragraph: (
            <>
                Dein Foto wurde erfolgreich hochgeladen. Wenn Du zu den
                Gewinnern zählst, werden wir Dich bis spätestens vier Wochen
                nach der Veranstaltung über den Gewinn informieren.
            </>
        ),
        alt: 'Illustration einer Apotherkerin neben einer Garnspindel',
    },
    noTourSection: {
        headline: (
            <i>
                <strong>Im Moment läuft leider</strong> keine Apothekentour!
            </i>
        ),
        paragraph:
            'Doch keine Sorge: Sobald eine Apothekentour geplant ist, findest Du sie in diesem Bereich.',
        alt: 'Illustration einer Apotherkerin vor einem Regal',
    },
    tourSection: {
        description: {
            headline: 'Die Apothekentour',
            paragraph: (
                <>
                    War die Apothekentour dieses Jahr in Deiner Nähe? Dann hast
                    Du uns bestimmt an unserem GeloMyrtol® forte Stand besucht
                    und ein Foto gemacht. Dieses kannst Du unten auf dieser
                    Seite hochladen. Dann nimmst Du automatisch am Gewinnspiel
                    teil und kannst eine von 10 KitchenAids gewinnen.
                    <br />
                    <br />
                    Wir drücken Dir die Daumen!
                </>
            ),
        },
        steps: [
            {
                headline: 'Foto machen',
                paragraph: (
                    <>
                        Du warst auf der Apothekentour 2022 an unserem
                        GeloMyrtol® forte Stand und hast ein Foto an der
                        Fotowand gemacht.
                    </>
                ),
                alt: 'Illustration Fotoapparat',
                image: <Photo />,
            },
            {
                headline: 'Foto hochladen',
                paragraph: (
                    <>
                        Das Foto von der Apothekentour hast Du nun per E-Mail
                        erhalten. Du kannst es auf dieser Seite unten im
                        Upload-Fenster hochladen.
                    </>
                ),
                alt: 'Illustration Foto',
                image: <Work />,
            },
            {
                headline: 'Am Gewinnspiel teilnehmen',
                paragraph: (
                    <>
                        Wenn Du den Teilnahmebedingungen zugestimmt hast, kannst
                        Du Dein Foto von der Apothekentour hochladen. Jetzt
                        heißt es Daumen drücken. Wir benachrichtigen jeden
                        Gewinner bis spätestens vier Wochen nach jeder
                        Veranstaltung.
                    </>
                ),
                alt: 'Illustration drei Sterne',
                image: <Stars />,
            },
        ],
    },
    dragAndDropSection: {
        headline: 'Ziehe Dein Bild hier hin',
        paragraph: (
            <>
                Wähle das Bild von der Apothekentour aus und lege es hier ab.
                Alternativ kannst Du Dein Bild auch direkt aus einem Ordner
                auswählen.
            </>
        ),
        button: 'Bild auswählen',
        form: {
            headline: 'Deine Bildauswahl',
            noImageSelected: 'Du hast noch kein Bild ausgewählt.',
            imageRejected: {
                reasonSize: 'Die Datei darf nicht größer als 5 MB sein.',
                reasonFormat:
                    'Bitte lade ein Bild im Format .png, .jpg oder .gif hoch.',
            },
            checkbox: {
                name: 'termsOfUse',
                label: (
                    <>
                        Ich habe die{' '}
                        <Link
                            href={`${routes.nutzungsbedingungen}/#teilnahmebedingungen`}>
                            Teilnahmebedingungen
                        </Link>{' '}
                        gelesen und stimme diesen zu.
                    </>
                ),
            },
            submit: 'Bild hochladen',
        },
    },
    apothekentourSubmission: {
        success: {
            headline: 'Fotoupload erfolgreich',
            paragraph: (
                <>
                    Du hast Dein Foto erfolgreich hochgeladen und nimmst nun am
                    GeloMyrtol® forte Gewinnspiel teil. Wir informieren die
                    Gewinner per E-Mail spätestens vier Wochen nach jeder
                    Veranstaltung.
                    <br />
                    <br />
                    Du möchtest jetzt Wissenspunkte auf der Apotique sammeln und
                    gegen attraktive Prämien oder Gutscheine einlösen?
                    <br />
                    Dann schau doch mal in unserer Trainingskollektion vorbei
                    und absolviere spannende Trainings.
                    <br />
                    <br />
                    <Link href={`${routes.trainings}`} showAsButton>
                        Zur Trainingskollektion
                    </Link>{' '}
                </>
            ),
        },
    },
};
